import assetFragment from '@lib/fragments/asset-fragment';
import gql from 'graphql-tag';

import { addressFields } from './address-fragment';
import { categoryFields, subSubCategoryQuery } from './menu-item-fragment';

export const eventItem = gql`
  fragment eventItem on EventPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    dates {
      from
      until
    }
    frequency
    tags: content_tags {
      id: _id
      slug: _slug
      title
    }
  }
  ${assetFragment}
`;

export const locationItem = gql`
  fragment locationItem on LocationPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment { 
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    tags: content_tags {
      id: _id
      slug: _slug
      title
    }
    category {
      ${subSubCategoryQuery}

      icon
      category {
        icon
      }
    }
    coordinates {
      latitude
      longitude
    }
    address {
      ${addressFields}
    }
  }
  ${assetFragment}
`;

export const contentItem = gql`
  fragment contentItem on ContentPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    tags: content_tags {
      id: _id
      slug: _slug
      title
    }
  }
  ${assetFragment}
`;

export const routeItem = gql`
  fragment routeItem on RoutePage {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    distance
    routeType: route_type
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    tags: content_tags {
      id: _id
      slug: _slug
      title
    }
  }
  ${assetFragment}
`;

export const newsItem = gql`
  fragment newsItem on NewsPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    tags: content_tags {
      id: _id
      slug: _slug
      title
    }
  }
  ${assetFragment}
`;

export const mainCategoryItem = gql`
  fragment mainCategoryItem on MainCategory {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
  }
  ${assetFragment}
`;

export const subCategoryItem = gql`
  fragment subCategoryItem on SubCategory {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    category {
      ${categoryFields}
    }
  }
  ${assetFragment}
`;

export const subSubCategoryItem = gql`
  fragment subSubCategoryItem on SubSubCategory {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    category {
      ${categoryFields}
      category {
        ${categoryFields}
      }
    }
  }
  ${assetFragment}
`;
